<template>
  <div>
    <head-bar :title="activeCategory ? activeCategory.translation.title : ''">
      <template v-slot:left>
        <head-bar-link @click="onBackClick">
          <img src="../assets/arrow-left.svg" alt="" aria-hidden="true" />
          <span class="visually-hidden">Terug</span>
        </head-bar-link>
      </template>
    </head-bar>

    <app-layout classes="category">
      <div class="mt-2 mt-md-3 p-2 p-md-3 text-center" v-if="categoryLoadingState === 'loading'">
        <spinner />
        Een moment geduld...
      </div>

      <div class="p-2 p-md-3" v-else-if="activeCategory">
        <div
          class="cms-content mb-2 mb-md-3"
          v-if="activeCategory.translation.description"
          v-html="activeCategory.translation.description"
        />

        <div v-if="activeCategory.articles.length === 0">
          Er zijn geen snacks in deze categorie&hellip;
        </div>

        <media-item
          v-for="snack in activeCategory.articles"
          class="mb-2 mb-md-3"
          :media-item="snack.mediaItem ? snack.mediaItem : null"
          :key="snack.id"
          :url="snack.translation.url"
          :title="snack.translation.title"
          :unread="true"
          :description="snack.translation.description"
        />
      </div>
    </app-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppLayout from './AppLayout.vue';
import MediaItem from '../components/MediaItem.vue';
import HeadBar from '../components/HeadBar.vue';
import HeadBarLink from '../components/HeadBarLink.vue';
import Spinner from '../components/Spinner.vue';

export default {
  components: {
    Spinner,
    HeadBarLink,
    HeadBar,
    MediaItem,
    AppLayout,
  },

  async created() {
    const { slug } = this.$route.params;

    await this.$store.dispatch('loadCategory', slug);
  },

  computed: {
    ...mapGetters([
      'user',
      'activeCategory',
      'categories',
      'categoryLoadingState',
    ]),
  },

  methods: {
    onBackClick() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.category {
  padding-top: 3.5rem;
}
</style>
