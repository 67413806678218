<template>
  <div class="text-center">
    <loading
      :active="true"
      :can-cancel="false"
      :on-cancel="onCancel"
      color="#7a2766"
    />
  </div>
</template>

<script>
import Loading from 'vue3-loading-overlay';

export default {
  components: {
    Loading,
  },
};
</script>
